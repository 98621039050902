import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// firebase init = add your own config here
const firebaseConfig = {
	apiKey: 'AIzaSyDb_uFoEQQcrJeRmtz0frgJaxI9eCvQ5w8',
	authDomain: 'swatcms-admin.firebaseapp.com',
	databaseURL: '',
	projectId: 'swatcms-admin',
	storageBucket: 'swatcms-admin.appspot.com',
	messagingSenderId: '910941225863',
	appId: '1:910941225863:web:4ea362b5252e437750c922'
}

firebase.default.initializeApp(firebaseConfig)

// utils
const db = firebase.default.firestore()
const auth = firebase.default.auth()
const storage = firebase.default.storage()

// collection references
const usersCollection = db.collection('users');
const websitesCollection = db.collection('websites');
const pagesCollection = db.collection('pages');
const formsCollection = db.collection('forms');

export {
	db,
	auth,
	storage,
	usersCollection,
	websitesCollection,
	pagesCollection,
	formsCollection
}
